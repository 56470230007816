body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table-thead > tr > th.ant-table-column-has-actions {
  background-clip: padding-box;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters, .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  background-clip: padding-box;
}

@media (max-width: 800px) {
  body .ant-modal {
    top: 10px;
  }

  .ant-layout-content {
    min-width: 300px;
  }
}


// SMALL TABLE
.small-table {

	td {
		vertical-align: middle !important;
	}
	
	.ant-table-thead {
		max-height: 41px;
		tr th {
			padding: 10px !important;
		}
	}

	.ant-table-tbody .ant-select-selection__rendered {
		margin-right: 3px;
		margin-left: 3px;
	}

	.ant-table-tbody .ant-form-item-control {
		line-height: 33px;
	}

	.ant-table-body .ant-select-selection {
		border-radius: 0;
	}

	.ant-table-body .ant-table-tbody > tr > td input {
		border-radius: 0;
	}

	.ant-table-body .ant-table-tbody > tr > td {
		padding: 0 3px;
		vertical-align: middle !important;
	}

	.ant-form-item-children input {
		padding: 3px;
	}

	.editable-item .tr .td {
		padding: 0;
	}

	.ant-table-tbody tr.ant-table-row .editable-item {
		padding: 0;
	}	

	table {
		thead .ant-table-expand-icon-th {
			border: none;
			padding: 0 !important;
			width: 10px;
			min-width: 10px;
			position: absolute;
		}
		tbody .ant-table-row {
			position: relative;
			.ant-table-row-expand-icon-cell {
				border-right: none;
				background: transparent;
				vertical-align: middle;
			}	
		}

	
		tbody tr td:nth-child(2) {
			// padding-left: 0px !important;
			// padding: 0px !important;
			// width: 80px;
		}
		.ant-table-tbody tr {
			.editable-row {
				td {
					padding: 0px 8px !important;
				}
			}
			td {
				padding: 0px 8px !important;
				min-height: 30px;
				&.editable-item {
					padding: 0px !important;
				}
			} 
		}
		.ant-table-expanded-row {
			.hidden-actions {
				display: inline-flex;
				flex-direction: row;
				gap: 10px;
			}
		}

		.ant-table-expanded-row > td {
			border: none;
			padding: 10px !important ;
		}
  }

  &.no-editable tbody tr {
	.anticon-delete {
		padding-left: 4px;
	}
	
	.ant-table-row-expand-icon-cell {
		// padding: 1px 0px !important;
	}
  }

	.ant-btn-icon-only {
		width: 20px;
		height: 20px;
	}

  &.simple-actions{
	tbody tr {
		td {
			vertical-align: middle;
		}
		td:nth-child(1) {
			width: 50px;
		}

		td:nth-child(2) {
			vertical-align: middle;
		}
	}
  }
	.ant-table-footer {
		background-color: #ffffff;
	}
}

// END SMALL TABLE
